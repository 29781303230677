import '../css/Biopic.css';
import { Image } from '../assets/Images';
import { imageURLs } from '../assets/Images';

export default function Biopic() {
  return (
    <div className="biopic">
        <div className='biopic-bio'>
            <p>Hey! I'm Jay. I'm a Senior Product Manager at Tinder, where I use machine learning to improve user profiles and recommendations.</p>
            <p>I'm also a tutor and college/career counselor! Check out the <a href="#tutoring-section">details</a> below to see what services I offer.</p>
            <p>Before Tinder, I was at Spotify, where I helped control how many ads we served on music and podcasts.
              (So if you suddenly starting getting tons of ads on Spotify about 2 years ago... my bad).
              Before that I was a software engineer at Microsoft working on various AI features for Azure and Lobe.ai.
            </p>
            <p>Work aside - I'm a SoCal native based in Irvine.
              I'm a music lover, and spend much of my time playing piano, arranging covers, and DJing.
              On Sundays you can catch me watching a Formula 1 race or a tennis final, and attending car meets.
              I'm also a huge geography nerd - try quizzing me about any world capital, demographics, landmarks, etc.
            </p>
        </div>
        <div className="biopic-pic-container">
          <Image imageType={imageURLs.profile} className='biopic-pic' />
        </div>
    </div>
  );
}