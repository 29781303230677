import '../css/Tutoring.css';
import { calendlyLink } from '../App';

export const Tutoring = () => {
  return (
    <div className='section' id='tutoring-section'>
      <div className='section-title'>
        Tutoring
      </div>
      <p>
        In addition to my day job, I'm a math and computer science tutor. I cover the following topics:
      </p>
      <ul>
        <li>Any grade school math subject up to calculus</li>
        <li>Single-variable calculus (AP Calculus AB/BC)</li>
        <li>Object-oriented programming (AP Computer Science)</li>
        <li>Data structures and algorithms</li>
        <li>Java, python, and web development</li>
      </ul>
      <p>I'm also an admissions counselor for undergrad, MS programs, medical school, and business school. 
        Services I offer include but are not limited to:
      </p>
      <ul>
        <li>College app essay brainstorming and revision (undergrad, MS programs, MBA programs, medical school, medical residency)</li>
        <li>General advising about which schools to apply to, which major(s) to pick, and what career path to follow</li>
        <li>Overall how to put together the best possible package to help you achieve your dream program / school</li>
      </ul>
      <p>
        And if you are already a college student, I offer general career counseling for any STEM program – engineering, pre-med, math, economics, physics, bio, etc:
      </p>
      <ul>
        <li>Planning your course schedule, which classes to take, and how to succeed in those classes</li>
        <li>Resume feedback and revision for STEM job applications, both internships and full-time</li>
        <li>Interview preparation for software engineering and product management, both internships and full-time</li>
        <li>How to navigate career transitions within the tech industry – between engineering, product, design, management, data science, etc.</li>
        <li>How to pursue research and other extracurricular opportunities</li>
      </ul>
      <div className='subsection-title'>
        Past Clients
      </div>
      <p>
        Graduate programs my clients have been admitted to:
      </p>
      <ul>
        <li>Harvard Business School, MBA</li>
        <li>Stanford Graduate School of Business, MBA</li>
        <li>Stanford School of Medicine, MD</li>
        <li>Icahn School of Medicine at Mount Sinai, MD</li>
        <li>Yale School of Medicine, MD</li>
        <li>Carnegie Mellon University, Masters in Computer Science</li>
        <li>University of Michigan, Masters in Bioengineering</li>
        <li>UCLA, Emergency Medicine Residency</li>
        <li>UCSF, Anesthesiology Residency</li>
        <li>UC Berkeley, Masters in Bioengineering</li>
        <li>UC Berkeley, Masters in EECS</li>
        <li>UCLA, Masters in Computer Science</li>
        <li>UCLA, Masters in Bioengineering</li>
        <li>USC, Masters in Electrical Engineering</li>
        <li>USC, Masters in Computer Science</li>
        <li>UC San Diego, Masters in Electrical Engineering</li>
        <li>UC San Diego, Masters in Computer Science</li>
        <li>UC San Diego, Masters in Bioengineering</li>
        <li>UC Irvine, Masters in Bioengineering</li>
        <li>UC Davis, Masters in Bioengineering</li>
      </ul>
      <p>
        Undergraduate programs my clients have been admitted to:
      </p>
      <ul>
        <li>University of Pennsylvania, Bioengineering</li>
        <li>Duke University, Bioengineering</li>
        <li>UCLA, Electrical Engineering</li>
        <li>UC Berkeley, Electrical Engineering & Computer Science</li>
        <li>USC, Electrical Engineering</li>
        <li>UC San Diego, Bioengineering</li>
        <li>Pomona College, Bioengineering</li>
        <li>UC Riverside, Applied Math</li>
        <li>UC Riverside, Data Science</li>
      </ul>
      <p>
        Jobs and internships my clients have secured:
      </p>
      <ul>
        <li>ML Engineer at Google</li>
        <li>Cloud Engineer at Microsoft Azure</li>
        <li>Cloud Engineer at Amazon Web Services</li>
        <li>Infra Engineer at Salesforce</li>
        <li>Data Engineer at Facebook</li>
        <li>Associate at Khosla Ventures</li>
        <li>Trader at Bridgewater Associates</li>
        <li>Analyst at Goldman Sachs</li>
        <li>Analyst at JP Morgan</li>
        <li>Software Engineer at Capital One</li>
        <li>Product Manager at Petco</li>
        <li>Research Associate at Caltech</li>
      </ul>
      <p>
        You can see more info about my background and qualifications <a href='#resume-section'>here</a>.
      </p>
      {/* <p>
        You can schedule a session with me <a href={calendlyLink} target='_blank' rel='noreferrer noopener'>here</a> or shoot me a text at (949) 742-2470 for more info.
      </p> */}
      <p>
        If you're interested, shoot me a text at (949) 742-2470.
      </p>
    </div>
  );
}
