/*
export const Company = {
  tinder,
  spotify,
  lobe,
  microsoft,
  amazon,
  marvell,
  ucsd
}
*/

export const imagePaths = {
  profile: '%PUBLIC_URL%/profile.JPG',
  tinder: '%PUBLIC_URL%/tinder.jpg',
  spotify: '%PUBLIC_URL%/spotify.png',
  lobe: '%PUBLIC_URL%/lobe.png',
  microsoft: '%PUBLIC_URL%/microsoft.png',
  amazon: '%PUBLIC_URL%/amazon.png',
  marvell: '%PUBLIC_URL%/marvell.png',
  ucsd: '%PUBLIC_URL%/ucsd.webp'
}

export const imageURLs = {
  profile: "https://jaygandhi.s3.us-west-2.amazonaws.com/IMG_0946.JPG",
  tinder: "https://jaygandhi.s3.us-west-2.amazonaws.com/tinder-redesign-graphics_dezeen_sq-1.jpg",
  spotify: "https://jaygandhi.s3.us-west-2.amazonaws.com/Spotify_logo_without_text.svg.png",
  lobe: "https://jaygandhi.s3.us-west-2.amazonaws.com/25476191.png",
  microsoft: "https://jaygandhi.s3.us-west-2.amazonaws.com/microsoft-icon-1024x1024.png",
  amazon: "https://jaygandhi.s3.us-west-2.amazonaws.com/15380810_1352558938127850_6108266257485077821_n.png",
  marvell: "https://jaygandhi.s3.us-west-2.amazonaws.com/1586789596_1.png",
  ucsd: "https://jaygandhi.s3.us-west-2.amazonaws.com/rcyf1pzkyuyoylf9yawk.webp"
}

export const Image = ({className, imageType}) => {
  return (
    <img
      className={className}
      alt={className}
      src={imageType}
    />
  )
}
