import './App.css';
import Biopic from './components/Biopic';
import Resume from './components/Resume';
import { Tutoring } from './components/Tutoring';

export const calendlyLink = 'https://calendly.com/gandhiprep/1h-private-session';

function App() {
  const hour = new Date().getHours();
  const isDark = hour < 7 || hour > 17;

  return (
    <div className={'app' + (isDark ? ' app-dark-mode' : '')}>
      <header className="header"></header>
      <div className='profile-title'>
        <div className='full-name'>
          <span className='first-name'>Jay </span>
          <span className='last-name'>Gandhi</span>
        </div>
        {/* <div className='profile-subtitle'>Sr Product Manager, Tinder</div> */}
      </div>
      <Biopic />
      <Tutoring />
      <Resume />
      <div className='section'>
        <div className='section-title'>
          Contact
        </div>
        <div className='contact-info'>
          <div>Email: jaygandhi04@gmail.com</div>
          <div>Phone: (949) 742-2470</div>
        </div>
        <div className='contact-info'>Schedule a meeting with me <a href={calendlyLink}>here</a>.</div>
      </div>
    </div>
  );
}

export default App;
