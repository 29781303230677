import { imageURLs } from '../assets/Images';
import '../css/Resume.css';
import { ResumeItem } from './ResumeItem';

const education = {
  company: 'UC San Diego',
  title: 'B.S. Electrical & Computer Engineering, 3.95 GPA',
  description: 'Regents Scholar, Henry Booker Honors, Golden State Scholar, Computer Science Tutor.',
  image: imageURLs.ucsd
}

const workExperience = [
  {
    company: 'Tinder',
    title: 'Senior Product Manager | 2023-',
    description: 'Using machine learning to optimize user profiles and recommendations.',
    image: imageURLs.tinder
  },
  {
    company: 'Spotify',
    title: 'Product Manager II | 2021-2022',
    description: 'Using machine learning to grow the advertising business for music and podcasts.',
    image: imageURLs.spotify
  },
  {
    company: 'Lobe',
    title: 'UX Engineer | 2020-2021',
    description: 'Democratizing machine learning through no-code image classifiers.',
    image: imageURLs.lobe
  },
  {
    company: 'Microsoft',
    title: 'Backend Engineer | 2018-2020',
    description: 'Delivering machine learning features to enterprise cloud customers.',
    image: imageURLs.microsoft
  },
  {
    company: 'Amazon',
    title: 'Software Engineer Intern | 2017',
    description: 'Optimizing the developer on-call experience through AWS and Elasticsearch.',
    image: imageURLs.amazon
  },
  {
    company: 'Marvell',
    title: 'Hardware Engineer Intern | 2016',
    description: 'Building pipeline automation for ASIC verification and writing verilog testbenches.',
    image: imageURLs.marvell
  },
  {
    company: 'UC San Diego',
    title: 'Computer Science Tutor | 2016-2017',
    description: 'Tutoring an undergrad course on object-oriented programming in Java.',
    image: imageURLs.ucsd
  }
]

export default function Resume() {
  return (
    <div className='section' id='resume-section'>
      <div className='section-title'>
        Resumé
      </div>
      <div className='subsection-title'>
        Education
      </div>
      <ResumeItem props={education} />
      <div className='subsection-title'>
        Experience
      </div>
      <ResumeItem props={workExperience[0]} />
      <ResumeItem props={workExperience[1]} />
      <ResumeItem props={workExperience[2]} />
      <ResumeItem props={workExperience[3]} />
      <ResumeItem props={workExperience[4]} />
      <ResumeItem props={workExperience[5]} />
      {/* <ResumeItem props={workExperience[6]} /> */}
    </div>
  );
}