import { Image } from "../assets/Images"

export const ResumeItem = ({props}) => {
    return (
        <div className='resume-item'>
            <Image
                imageType={props.image}
                className={props.company.toLowerCase().replace(/\s/g,'-')}
            />
            <div className='resume-item-copy'>
                <div className='resume-item-company'>{props.company}</div>
                <div className='resume-item-title'>{props.title}</div>
                <div className='resume-item-description'>{props.description}</div>
            </div>
        </div>
    )
}